// @flow

//React
import React, { useContext } from 'react'
import { Form, Button } from 'antd'

//Contexts
import { store } from '~contexts/store'

function FormButton({ props }: Object) {
  const { state, asyncDispatch } = useContext(store)
  const { questSchema } = state
  const { panels } = questSchema
  const { form, formType, gid, item, setError } = props
  const { label, onChange } = item
  const { dispatch, type } = onChange

  const setGroupValues = () => {
    let groupValues = {
      gid,
      answers: {},
    }
    const group = panels.filter((item: Object) => item.gid === gid)[0]
    if (group) {
      group.items.map((item: Object) => {
        if (item.inputType != 'button') {
          const formItemValue = form.getFieldValue(item.name)
          groupValues.answers[`${item.name}`] = formItemValue
        }
      })
    }
    return groupValues
  }

  const onFinishCheckout = (values: any) => {
    ;(async () => {
      const receivedError = await asyncDispatch({ type: dispatch, value: values, state: state })
      if (receivedError) {
        setError(receivedError)
      } else {
        setError({})
      }
    })()
  }

  const onFinishQuest = () => {
    const values = setGroupValues()
    ;(async () => {
      const receivedError = await asyncDispatch({ type: dispatch, value: values, state: state })
      if (receivedError) {
        setError(receivedError)
      } else {
        setError({})
      }
    })()
  }

  const onClick = () => {
    if (formType === 'quest') {
      onFinishQuest()
    } else if (formType === 'checkout') {
      const values = form.getFieldsValue()
      onFinishCheckout(values)
    }
  }

  return (
    <Form.Item>
      <Button className="capitalize form-submit" htmlType="submit" onClick={onClick} type="primary">
        {label}
      </Button>
    </Form.Item>
  )
}

export default FormButton
