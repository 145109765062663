// @flow

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Col, Row, Form, Input, Radio, DatePicker } from 'antd'
import { UserOutlined, LockOutlined, MailOutlined, CheckSquareOutlined } from '@ant-design/icons'

// Components
import GenModal from '~components/modal'
import RegisterForm from '~components/forms/register'

export default function RegisterButton() {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button className="button register-button" onClick={showModal} size="middle" type="text">
        Register
      </Button>
      <GenModal onCancel={handleCancel} visible={isModalVisible}>
        <RegisterForm />
      </GenModal>
    </>
  )
}
