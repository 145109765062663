// @flow

//React
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { store } from '~contexts/store'

import shoppingCart from '~images/shopping-cart.png'

function BuyButton({ item }: Object) {
  const { dispatch } = useContext(store)

  const onClick = () => {
    dispatch({ type: 'common.setCompareItem', value: item })
  }

  return (
    <Button className="card-button" onClick={onClick} type="text">
      {item.checkout !== 'internal' && item.checkout !== '' ? (
        <Link
          target="_blank"
          to={{
            pathname: item.checkout,
          }}>
          Buy <img src={shoppingCart} width="20" />
        </Link>
      ) : (
        <Link
          to={{
            pathname: 'checkout',
          }}>
          Buy <img src={shoppingCart} width="20" />
        </Link>
      )}
    </Button>
  )
}

export default BuyButton
