// @flow

//React
import React, { useState, useEffect } from 'react'
import { Form, Input, Typography, DatePicker } from 'antd'

function FormInput({ props }: Object) {
  const [customError, setCustomError] = useState({})
  const { error, item, formType } = props
  const { label, type, name, required, errorMessage, ruleType, ruleMessage, max } = item

  useEffect(() => {
    if (name === error.key) {
      setCustomError({
        message: error.message,
        status: 'error',
      })
    } else {
      setCustomError({})
    }
  }, [error])

  return (
    <>
      <Typography.Text className="capitalize">{label}</Typography.Text>
      <Form.Item extra={customError.message} name={name} validateStatus={customError.status}>
        <Input maxLength={max} />
      </Form.Item>
    </>
  )
}

export default FormInput
