// @flow

//React
import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'

//Styles
import '~styles/buttons.scss'

function CheckboxGroupFilter({ filterFn, props, resetFn }: Object) {
  const { labelKey, options, subType } = props
  const [values, setValue] = useState([])

  const applyFilter = (listData: Array) => {
    return listData.filter((item: Object) =>
      values.some((value: string | Array) => {
        let mappedItem
        let min
        let max
        if (Array.isArray(value)) {
          ;[min, max] = value
          item.infos.map((info: Object) => {
            if (info.title === labelKey) {
              if (info.value > min && info.value <= max) {
                mappedItem = item
              }
            }
          })
        } else {
          item.infos.map((info: Object) => {
            if (info.title === labelKey) {
              if (info.value === value) {
                mappedItem = item
              }
            }
          })
        }
        return mappedItem
      })
    )
  }
  const resetFilters = () => {
    setValue([])
  }

  const resetFilter = (listData: Array) => {
    return listData
  }
  const handleChange = (checkedValues: Array) => {
    setValue(checkedValues)
  }

  useEffect(() => {
    return resetFn(`checkbox-${labelKey}`, resetFilters)
  }, [])

  useEffect(() => {
    if (values.length) return filterFn(`checkbox-${labelKey}`, applyFilter)
    return filterFn(`checkbox-${labelKey}`, resetFilter)
  }, [values])

  return (
    <>
      <Checkbox.Group onChange={handleChange} options={options} value={values} />
    </>
  )
}

export default CheckboxGroupFilter
