// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'antd'

function QuestSubmitButton() {
  return (
    <Row>
      <Col>
        <Button>
          <Link
            to={{
              pathname: 'results',
            }}>
            Submit
          </Link>
        </Button>
      </Col>
    </Row>
  )
}

export default QuestSubmitButton
