// @flow

import _ from 'lodash'

//React
import React, { createContext, useCallback, useReducer } from 'react'
import { notification } from 'antd'
import { theme, catalogId, dataAdClient } from '~config'

//Reducers
import { apiReducer, apiActions } from './reducers/api'
import { resultsReducer } from './reducers/results'
import { commonReducer } from './reducers/common'

const instanceMapper = {
  '1': 'beer',
  '2': 'travel',
}

const getInstance = (catalogId: string) => {
  return instanceMapper[`${catalogId}`]
}

const storeReducer = (state: Object, action: string) => {
  const page = _.head(_.split(action.type, '.'))
  switch (page) {
    case 'results':
      return resultsReducer(state, action)
    case 'common':
      return commonReducer(state, action)
    case 'api':
      return apiReducer(state, action)
    default:
      throw new Error()
  }
}

const initialState = {
  activeFilters: [],
  catalogId,
  checkoutItem: {},
  checkoutSchema: {},
  comparedItems: {
    left: {},
    right: {},
  },
  dataAdClient,
  filteredResults: [],
  filtersCollapsed: true,
  filtersData: [],
  homepageConfig: {},
  instanceConfig: {},
  instanceTheme: getInstance(catalogId),
  isFetching: false,
  questAnswers: {},
  questSchema: [],
  resultsData: [],
  selectedItemsArray: [],
  selectedItemsArray: [],
  theme: theme,
}

export const store = createContext(initialState)
const { Provider } = store

export const StoreProvider = ({ children }: Object) => {
  const [state, dispatch] = useReducer(storeReducer, initialState)
  const asyncDispatch = useCallback(
    (action: Object) => {
      switch (action.type) {
        case 'api.orderValidation':
        case 'api.addResponse':
          return apiActions[action.type](action)
        case 'api.getResults':
        case 'api.getFilters':
        case 'api.getQuest':
        case 'api.getCheckoutSchema':
        case 'api.getInstanceConfig':
        case 'api.getHomepageConfig':
          return apiActions[action.type](catalogId)
        default:
          dispatch(action)
      }
    },
    [dispatch]
  )
  return <Provider value={{ state, dispatch, asyncDispatch }}>{children}</Provider>
}
