// @flow

//React
import React, { useContext } from 'react'
import { Layout as AntdLayout, Row, Button, Col } from 'antd'
import { Link } from 'react-router-dom'
import LoginButton from '~components/buttons/login'
import RegisterButton from '~components/buttons/register'
import User from '~components/buttons/user'
import UserConfig from '~components/buttons/user-config'
import { useAuth0 } from '@auth0/auth0-react'
import { store } from '~contexts/store'

//Styles
import '~styles/header.scss'

const NotLoggedIn = () => {
  return (
    <>
      <LoginButton />
      <RegisterButton />
    </>
  )
}

const IsLoggedIn = () => {
  return (
    <>
      <User />
      <UserConfig />
    </>
  )
}

function Header() {
  const { state } = useContext(store)
  const { instanceTheme } = state
  const { isAuthenticated } = useAuth0()

  return (
    <AntdLayout.Header className="header-wrapper">
      <Row align="middle" className="header-content-wrapper">
        <Col className="logo-wrapper" justify="center" lg={{ offset: 6, span: 12 }} md={14} sm={16} xs={10}>
          <Link to="/">
            <div className="header-logo" />
          </Link>
        </Col>
        <Col className="button-wrapper" lg={6} md={10} sm={8} xs={14}>
          <Row align="middle" className="btn-inside-wrapper" justify="end">
            {isAuthenticated ? <IsLoggedIn /> : <NotLoggedIn />}
          </Row>
        </Col>
      </Row>
    </AntdLayout.Header>
  )
}

export default Header
