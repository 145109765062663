// @flow

import React, { useContext } from 'react'
import { Form, Button } from 'antd'

//Contexts
import { store } from '~contexts/store'

function QuestSubmitButton({ props }: Object) {
  const { item, form, collapseProperties, gid, setError, setIsCollapsed } = props
  const { onChange } = item
  const { dispatch } = onChange
  const { state, asyncDispatch } = useContext(store)
  const { questSchema } = state
  const { panels } = questSchema

  const onClick = () => {
    let groupValues = {
      gid,
      answers: {},
    }
    const group = panels.filter((item: Object) => item.gid === gid)[0]
    if (group) {
      group.items.map((item: Object) => {
        if (item.inputType !== 'submitButton' && item.inputType !== 'skipButton') {
          const formItemValue = form.getFieldValue(item.name)
          groupValues.answers[`${item.name}`] = formItemValue
        }
      })
    }
    ;(async () => {
      const receivedError = await asyncDispatch({ type: dispatch, value: groupValues, state: state })
      if (receivedError) {
        setError(receivedError)
      } else {
        const questAnswers = await asyncDispatch({ type: 'api.setQuestAnswers', value: groupValues })
        collapseProperties.collapse.onItemClick('0')
        setIsCollapsed(true)
        setError({})
      }
    })()
  }

  return (
    <Form.Item className="quest-submit-button-wrapper" name={name}>
      <Button className="quest-submit-button" onClick={onClick}>
        Submit
      </Button>
    </Form.Item>
  )
}

export default QuestSubmitButton
