// @flow
import _ from 'lodash'

import { Col, Row, Typography, Button } from 'antd'

import React from 'react'
import intagramIcon from '~images/instagram_products.png'
import linkIcon from '~images/link_product.png'
import facebookIcon from '~images/facebook_products.png'

import '~styles/item-details.scss'

const ItemDetails = ({ props }: Object) => {
  const { item } = props
  return (
    <div>
      {item.additionalInfo.map((info: string, index: number) => {
        if (info.title === 'image') {
          return
        }
        return (
          <Row className="add-info" key={index}>
            <Col lg={4} md={4} sm={6} xs={8}>
              <Typography.Text className="capitalize title">{info.title} :</Typography.Text>
            </Col>
            <Col className="text" lg={20} md={20} sm={18} xs={14}>
              <Typography.Text>{info.value}</Typography.Text>
            </Col>
          </Row>
        )
      })}
      <Row justify="end">
        <Col className="social">
          <a href="#" target="_blank">
            <img src={facebookIcon} width="20" />
          </a>
        </Col>
        <Col className="social">
          <a href="#" target="_blank">
            <img src={intagramIcon} width="20" />
          </a>
        </Col>
        <Col className="social">
          <a href="#" target="_blank">
            <img src={linkIcon} width="20" />
          </a>
        </Col>
      </Row>
    </div>
  )
}

export default ItemDetails
