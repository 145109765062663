// @flow

//React
import React from 'react'
import { Row, Col, Collapse } from 'antd'

import QuestGroup from '~components/quest-form/quest-group'
import QuestSubmitButton from '~components/buttons/quest-submit-button'

function QuestForm({ schema }: Object) {
  const { panels } = schema

  if (Object.keys(schema).length === 0) {
    return <p>Default</p>
  }
  return (
    <Row justify="center">
      <Col className="form-wrapper">
        {panels.map((item: Object, idx: number) => {
          return <QuestGroup key={idx} props={item} />
        })}
        <QuestSubmitButton />
      </Col>
    </Row>
  )
}

export default QuestForm
