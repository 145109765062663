// @flow

//React
import React, { useState } from 'react'
import { Form, Radio, Typography } from 'antd'

function FormRadioGroup({ props }: Object) {
  const { item, formType } = props
  const { description, label, name, required, options, errorMessage } = item

  const getDefaultValue = (options: Array) => {
    const items = options.filter((item: Object) => item.selected)
    if (items.length >= 1) {
      return items[0].value
    }
    return
  }

  const [defaultValue, setDefaultValue] = useState(() => getDefaultValue(options))

  return (
    <Form.Item initialValue={defaultValue} name={name} rules={[{ required: required, message: errorMessage }]}>
      <Radio.Group className="form-item">
        <Typography.Title className={`${formType}-title capitalize`}>{label}</Typography.Title>
        <Typography.Title className={`${formType}-description capitalize`}>{description}</Typography.Title>
        {options.map((item: Object, idx: number) => {
          if (item.type === 'radio') {
            return (
              <Radio className={`${formType}-options capitalize`} key={idx} value={item.value}>
                {item.label}
              </Radio>
            )
          }
          return (
            <Radio.Button className={`${formType}-options capitalize`} key={idx} value={item.value}>
              {item.label}
            </Radio.Button>
          )
        })}
      </Radio.Group>
    </Form.Item>
  )
}

export default FormRadioGroup
