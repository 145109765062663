// @flow

import _ from 'lodash'

//React
import React, { useContext, useEffect } from 'react'

//Components
import HomePageBanner from '~components/homepage-sections/banner'

//Contexts
import { store } from '~contexts/store'

function HomePageSections() {
  const { state, dispatch, asyncDispatch } = useContext(store)
  const { homepageConfig } = state
  useEffect(() => {
    ;(async () => {
      try {
        const homepageConfig = await asyncDispatch({ type: 'api.getHomepageConfig' })
        dispatch({ type: 'common.setHomepageConfig', value: homepageConfig })
      } catch (e) {
        dispatch({
          type: 'common.setHomepageConfig',
          value: {
            label: '404',
            linkTo: '/404',
          },
        })
      }
    })()
  }, [])
  return (
    <div className="homepage-background">
      <HomePageBanner />
    </div>
  )
}

export default HomePageSections
