// @flow

//React
import React from 'react'
import { List as AntdList, Card, Col, Layout, Row, Typography, Button } from 'antd'
import AdBanner from '~components/ad-banner'

//Components
import Filters from '~components/filters'
import ListItem from '~components/list/list-item'

import '~styles/list.scss'

const List = ({ filteredResults }: Object) => {
  return (
    <AntdList
      dataSource={filteredResults}
      itemLayout="vertical"
      renderItem={(item: string, index: int) => (
        <AntdList.Item key={index}>
          {/*<AdBanner props={{ slot: '1' }} />*/}
          <ListItem props={{ item }} />
        </AntdList.Item>
      )}
    />
  )
}

export default List
