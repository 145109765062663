// @flow

//React
import React from 'react'
import { Row, Col } from 'antd'

import infoIcon from '~images/info.png'

function ExpandIcon({ infoText, props }: Object) {
  return (
    <Row className="info-btn-label">
      <Col span={24}>
        {infoText}&nbsp;&nbsp;
        <img className="more-info" src={infoIcon} width="20" />
      </Col>
    </Row>
  )
}

export default ExpandIcon
