// @flow

//React
import React, { useEffect, useState, useContext } from 'react'
import { Slider, Typography } from 'antd'

//Styles
import '~styles/filters.scss'

function RangeFilter({ filterFn, props, resetFn }: Object) {
  const { labelKey, max, min } = props
  const [value, setValue] = useState([min, max])

  const applyFilter = (listData: Array) => {
    return listData.filter((item: Object) => {
      let mappedItem
      if (item[`${labelKey}`]) {
        if (item[`${labelKey}`].value >= value[0] && item[`${labelKey}`].value <= value[1]) {
          mappedItem = item
        }
      } else {
        item.infos.map((info: Object) => {
          if (info.title === labelKey) {
            if (info.value >= value[0] && info.value <= value[1]) {
              mappedItem = item
            }
          }
        })
      }
      return mappedItem
    })
  }

  const resetFilters = () => {
    setValue([min, max])
  }

  const handleChange = (value: Any) => {
    setValue(value)
  }

  useEffect(() => {
    return resetFn(`range-${labelKey}`, resetFilters)
  }, [])

  useEffect(() => {
    return filterFn(`range-${labelKey}`, applyFilter)
  }, [value])

  return (
    <>
      <Slider defaultValue={[min, max]} max={max} onChange={handleChange} range value={value} />
      <Typography.Text>
        {value[0]}&#8364; - {value[1]}&#8364;
      </Typography.Text>
    </>
  )
}

export default RangeFilter
