// @flow

//React
import React from 'react'
import { Row, Col, Typography } from 'antd'

//Style
import '~styles/form.scss'

function OrderPreview({ props }: Object) {
  console.log(props)
  return (
    <>
      <Row>
        <Typography.Text>Review Order</Typography.Text>
      </Row>
      <Row>
        <Col span={12}>
          <Typography.Text>Product</Typography.Text>
        </Col>
        <Col className="text-right" span={12}>
          <Typography.Text>Total</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Typography.Text>{props.title}</Typography.Text>
        </Col>
        <Col className="text-right" span={12}>
          <Typography.Text>
            {props.price.value} {props.price.symbol}
          </Typography.Text>
        </Col>
      </Row>
    </>
  )
}

export default OrderPreview
