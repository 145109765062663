// @flow

//React
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { store } from '~contexts/store'

function Head() {
  const { state } = useContext(store)
  const { dataAdClient } = state
  return (
    <Helmet>
      <script
        async
        data-ad-client={dataAdClient}
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />
    </Helmet>
  )
}

export default Head
