// @flow

//React
import React, { useContext, useEffect, useState } from 'react'
import { store } from '~contexts/store'

//Styles
import '~styles/filters.scss'

//Imports
import RangeFilter from '~components/filters/range'
import CheckboxGroupFilter from '~components/filters/checkbox-group'
import RadioGroupFilter from '~components/filters/radio-group'
import ResetButton from '~components/filters/reset-button'

function Filters({ filtersData }: Object) {
  const [filtersFn, setFiltersFn] = useState([])
  const { asyncDispatch } = useContext(store)

  const filtersMapper = {
    range: RangeFilter,
    checkbox: CheckboxGroupFilter,
    radio: RadioGroupFilter,
  }
  const filterFn = (filterType: string, fn: function) =>
    asyncDispatch({ type: 'api.filterFn', filterFn: { fn, filterType } })

  const resetFn = (filterType: string, fn: function) => {
    setFiltersFn((prevState: Array) => [...prevState, { filterFn: { fn, filterType } }])
  }

  const resetFilters = () => {
    asyncDispatch({ type: 'api.resetFilters', filtersFn })
  }

  return (
    <>
      <div className="filter-wrapper">
        <div className="filters-container">
          <div className="filter-options">
            <ul>
              <li>
                <ResetButton resetFilters={resetFilters} />
              </li>
              {filtersData.map((filter: Object, idx: number) => {
                const Filter = filtersMapper[filter.type]
                return (
                  <li className="filter-group" key={idx}>
                    <div className="title">{filter.name}</div>
                    <Filter filterFn={filterFn} props={filter} resetFn={resetFn} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filters
