// @flow

//React
import React, { useContext, useEffect } from 'react'
import { Layout as AntdLayout, Row, Col } from 'antd'
import i18n from 'i18next'
import { useParams } from 'react-router-dom'

//Contexts
import { store } from '~contexts/store'

//Components
import Back from '~components/buttons/back'
import CompareItem from '~components/compare-item'
import Attributes from '~components/compare-item/attributes'

//Styles
import '~styles/compare.scss'

function Compare({ props }: Object) {
  const { state, dispatch } = useContext(store)
  const { selectedItemsArray, comparedItems } = state
  const { lng } = useParams()
  i18n.changeLanguage(lng)

  useEffect(() => {
    selectedItemsArray.slice(0, 2).map((item: Object, idx: number) => {
      if (idx % 2 == 0) {
        dispatch({ type: 'common.selectComparingItems', value: { item, side: 'left' } })
      } else {
        dispatch({ type: 'common.selectComparingItems', value: { item, side: 'right' } })
      }
    })
  }, [])

  return (
    <AntdLayout.Content className="site-content">
      <Row>
        <Col span={1}>
          <Back props={props} />
        </Col>
        <Col span={23}>
          <Row align="middle" className="items-wrapper" justify="space-between">
            {comparedItems.left.uuid && (
              <>
                <Col className="card-wrapper" lg={11} xs={12}>
                  <CompareItem item={comparedItems.left} selectedItemsArray={selectedItemsArray} side="left" />
                </Col>
              </>
            )}
            {comparedItems.right.uuid && (
              <Col className="card-wrapper" lg={11} xs={12}>
                <CompareItem item={comparedItems.right} selectedItemsArray={selectedItemsArray} side="right" />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </AntdLayout.Content>
  )
}

export default Compare
