// @flow

//React
import React, { useEffect, useState } from 'react'
import { Radio } from 'antd'

//Styles
import '~styles/buttons.scss'

function RadioGroupFilter({ filterFn, props }: Object) {
  const { labelKey, options } = props
  const [value, setValue] = useState(0)

  const applyFilter = (listData: Array) => {
    return listData.filter((item: Object) => {
      let mappedItem
      item.infos.map((info: Object) => {
        if (info.title === labelKey) {
          if (info.value === value) {
            mappedItem = item
          }
        }
      })
      return mappedItem
    })
  }

  const handleChange = (event: Any) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    if (value) return filterFn(`radio-${labelKey}`, applyFilter)
  }, [value])

  return (
    <>
      <Radio.Group onChange={handleChange} value={value}>
        {options.map((option: string, idx: number) => {
          return (
            <Radio className="radio-button" key={idx} value={option}>
              {option}
            </Radio>
          )
        })}
      </Radio.Group>
    </>
  )
}

export default RadioGroupFilter
