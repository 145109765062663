// @flow

//React
import React from 'react'
import { Layout as AntdLayout, Form, Input, Button, Row, Col, Checkbox } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
//Style
import '~styles/form.scss'

//Components
import CloseButton from '~components/buttons/close'

function Login() {
  const { loginWithPopup } = useAuth0()
  return (
    <Form className="login-form" initialValues={{ remember: true }} name="normal_login">
      <Col className="content">
        <Form.Item name="username" rules={[{ required: true, message: 'Please write your username.' }]}>
          <Input placeholder="Username" prefix={<UserOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please write your password.' }]}>
          <Input placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" noStyle>
            <Checkbox className="remember" defaultChecked={false}>
              Keep me signed in.
            </Checkbox>
          </Form.Item>
          <a className="login-form-forgot" href="">
            Forgot the password?
          </a>
        </Form.Item>
        <Form.Item>
          <Row justify="center">
            <Button className="login-button" htmlType="submit" type="primary">
              Sign in
            </Button>
          </Row>
          <Row className="register-now" justify="center">
            <p>Not registered?</p>
            <a href=""> Sign up.</a>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row justify="center">
            <Button className="login-button" onClick={() => loginWithPopup()} type="primary">
              Sign in w/ Auth0
            </Button>
          </Row>
        </Form.Item>
      </Col>
    </Form>
  )
}

export default Login
