// @flow

//React
import React, { useContext } from 'react'
import { Button } from 'antd'
import { store } from '~contexts/store'

//Styles
import '~styles/filters.scss'

function ResetButton({ resetFilters }: function) {
  const { asyncDispatch } = useContext(store)
  return (
    <Button className="reset-filter-button" onClick={resetFilters} type="text">
      Reset
    </Button>
  )
}

export default ResetButton
