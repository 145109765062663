module.exports = {
  key: 'beer',
  panels: [{
    key: 'billingAddress',
    title: 'Billing Address',
    items: [{
      errorMessage: 'Please input your First Name',
      id: 1,
      inputType: 'text',
      label: 'first name',
      name: 'firstName',
      required: true
    }, {
      errorMessage: 'Please input your Last Name',
      id: 2,
      inputType: 'text',
      label: 'last name',
      name: 'lastName',
      required: true
    }, {
      errorMessage: 'Please input your Email',
      id: 3,
      inputType: 'text',
      label: 'email',
      name: 'email',
      required: true
    }, {
      id: 4,
      inputType: 'text',
      label: 'phone number',
      name: 'phoneNumber',
      required: false
    }, {
      errorMessage: 'Please input your Address',
      id: 5,
      inputType: 'text',
      label: 'address',
      name: 'address',
      required: true
    }, {
      errorMessage: 'Please input your City',
      id: 6,
      inputType: 'text',
      label: 'city',
      name: 'city',
      required: true
    }, {
      errorMessage: 'Please input your Zip Code',
      id: 7,
      inputType: 'text',
      label: 'zip code',
      name: 'zipCode',
      required: true
    }, {
      errorMessage: 'Please input your Tax Identification Number',
      id: 8,
      inputType: 'text',
      label: 'tax identification number',
      name: 'taxIdentificationNumber',
      required: true
    }, {
      id: 9,
      inputType: 'checkbox',
      label: 'use same address for shipping',
      name: 'shippingAddress',
      required: false
    }]
  }, {
    key: 'paymentMethod',
    title: 'Payment Method',
    items: [{
      id: 1,
      label: '',
      name: 'paymentMethod',
      options: [{
        label: 'pay pal',
        value: 'paypal',
        type: 'radio'
      }, {
        label: 'MBWAY',
        value: 'mbway',
        type: 'radio'
      }, {
        label: 'Credit Card',
        value: 'cc',
        type: 'radio'
      }],
      required: true,
      errorMessage: 'Please select payment method',
      inputType: 'radio'
    }, {
      id: 2,
      label: 'Submit My Order',
      name: 'save',
      inputType: 'button',
      onChange: {
        dispatch: 'api.orderValidation',
        type: 'redirect',
        redirectTo: '/results'
      }
    }]
  }]
};