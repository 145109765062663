// @flow

//React
import React, { useContext } from 'react'
import { Layout as AntdLayout, Row, Col, Typography, Button } from 'antd'
import { Link } from 'react-router-dom'

//Styles
import '~styles/not-found.scss'

function NotFound() {
  return (
    <AntdLayout.Content className="site-content site-layout">
      <Row>
        <Col offset={6} span={10}>
          <Typography.Text className="error-title">404</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col offset={6} span={18}>
          <Typography.Text className="error-subtitle">Opps! This page could not be found.</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col offset={6} span={10}>
          <Typography.Text className="error-text">
            Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarily
            unavailable.
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col offset={6} span={10}>
          <Button className="hompepage-button" href="/">
            Homepage
          </Button>
        </Col>
      </Row>
    </AntdLayout.Content>
  )
}

export default NotFound
