// @flow

import React, { useState } from 'react'
import { Form } from 'antd'
import QuestOptions from '~components/quest-form/quest-options'
import QuestSubmitButton from '~components/quest-form/quest-submit-button'
import QuestSkipButton from '~components/quest-form/quest-skip-button'

function QuestBody({ props }: Object) {
  const [error, setError] = useState({})
  const [form] = Form.useForm()
  const { items, collapseProperties, gid, setIsCollapsed } = props

  const componentMapper = {
    radio: QuestOptions,
    submitButton: QuestSubmitButton,
    skipButton: QuestSkipButton,
  }
  console.log(items)
  return (
    <Form form={form}>
      {items.map((item: Object, idx: number) => {
        const QuestItem = componentMapper[item.inputType]
        return <QuestItem key={idx} props={{ item, form, collapseProperties, gid, error, setError, setIsCollapsed }} />
      })}
    </Form>
  )
}

export default QuestBody
