// @flow

//React
import React, { useContext } from 'react'
import { store } from '~contexts/store'
import { Button } from 'antd'

import addIcon from '~images/plus-circle.png'
import selectedIcon from '~images/check-circle.png'

function AddToCompare(props: Object) {
  const { state, dispatch } = useContext(store)
  const { item } = props

  const changeState = (ev: Object, item: Object) => {
    ev.preventDefault()
    dispatch({ type: 'common.onAddToCompare', value: item })
  }

  const toggleIcon = () => {
    if (!!item.selected) {
      return <img onMouseOut={(e: any) => (e.currentTarget.src = selectedIcon)} src={selectedIcon} width="20" />
    }
    return <img src={addIcon} width="20" />
  }

  return (
    <Button className="card-button" onClick={(ev: Object) => changeState(ev, item)} type="text">
      Slekt {toggleIcon()}
    </Button>
  )
}

export default AddToCompare
