// @flow

import React, { useState } from 'react'
import { Typography } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'

export default function User() {
  const { user } = useAuth0()
  const { given_name } = user
  return (
    <div className="user-name-wrapper">
      <Typography.Text strong> Hi {given_name}! </Typography.Text>
    </div>
  )
}
