// @flow

//React
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Layout as AntdLayout, Row, Col, Button, Typography } from 'antd'

//Contexts
import { store } from '~contexts/store'

function HomePageBanner() {
  const { state } = useContext(store)
  const { homepageConfig } = state

  return (
    <Row className="button-row" justify="start">
      <Col>
        <Button className="form-submit capitalize">
          <Link
            to={{
              pathname: `${homepageConfig.linkTo}`,
            }}>
            {homepageConfig.label}
          </Link>
        </Button>
      </Col>
    </Row>
  )
}

export default HomePageBanner
