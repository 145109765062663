// @flow
import _ from 'lodash'

import { Col, Row, Typography, Button, Divider } from 'antd'
import React from 'react'
import AddToCompare from '~components/buttons/add-to-compare'
import BuyButton from '~components/buttons/buy-button'
import placeholder from '~images/image-not-found-placeholder-default.png'

import '~styles/list-item.scss'

const ItemHeader = ({ props }: Object) => {
  const { item, collapseProperties } = props

  const moreInfo = () => {
    collapseProperties.onItemClick('0')
  }

  return (
    <Row className="item-header" onClick={(event: Any) => event.stopPropagation()}>
      <Col className="img-wrapper" lg={3} md={3} sm={3} xs={24}>
        <img
          alt="beer-logo"
          className="img"
          onError={(ev: any) => (ev.target.src = placeholder)}
          src={item.image || placeholder}
        />
      </Col>
      <Col className="card-content" lg={18} md={16} sm={14} xs={24}>
        <Row className="item-title-wrapper">
          <Col span={24}>
            <p className="item-title">{item.title}</p>
            <p className="item-subtitle">{item.subtitle}</p>
          </Col>
        </Row>
        <Row className="item-props-wrapper" gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          {_.map(item.infos, (info: string, index: number) => {
            if (index === 0) {
              return (
                <Col className="item-props" key={index} lg={4} md={16} sm={14} xs={24}>
                  <Row className="props-title">
                    <Typography.Text className="capitalize">{info.title}</Typography.Text>
                  </Row>
                  <Row className="props-text">
                    <Typography.Text className="capitalize">
                      {info.value} {info.symbol}
                    </Typography.Text>
                  </Row>
                </Col>
              )
            }
            return (
              <React.Fragment key={index}>
                <Divider className="divider" type="vertical" />
                <Col className="item-props" lg={4} md={16} sm={14} xs={24}>
                  <Row className="props-title">
                    <Typography.Text className="capitalize">{info.title}</Typography.Text>
                  </Row>
                  <Row className="props-text">
                    <Typography.Text className="capitalize">
                      {info.value} {info.symbol}
                    </Typography.Text>
                  </Row>
                </Col>
              </React.Fragment>
            )
          })}
        </Row>
      </Col>
      <Col className="card-buttons-wrapper" lg={3}>
        <Row className="hidden" justify="end">
          <Typography.Text className="capitalize price-value">
            {item.price.value} {item.price.symbol}
          </Typography.Text>
        </Row>
        <Row className="rows" justify="end">
          <AddToCompare item={item} />
        </Row>
        <Row className="rows" justify="end">
          <BuyButton item={item} />
        </Row>
        <Row className="rows" justify="end">
          <Button className="card-button more-info" onClick={moreInfo} type="text">
            More Info
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default ItemHeader
