// @flow

//React
import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'antd'

function Logout() {
  const { logout } = useAuth0()

  return (
    <Button className="button login-button" onClick={() => logout({ returnTo: window.location.origin })} type="text">
      Logout
    </Button>
  )
}

export default Logout
