// @flow

//React
import React from 'react'
import { Layout as AntdLayout, Row, Col } from 'antd'

//Styles
import '~styles/footer.scss'

//Imports
import logo from '~images/slekt-footer-logo.png'
import email from '~images/envelope-grey.png'
import facebook from '~images/facebook-grey.png'
import instagram from '~images/instagram-grey.png'
import twitter from '~images/twitter-grey.png'

function Footer() {
  return (
    <AntdLayout.Footer className="footer-wrapper">
      <div className="footer">
        <Row className="copyright-wrapper" justify="end">
          <p className="copyright-wrapper-text hspace">Copyright© 2020</p>
          <p className="copyright-wrapper-text hspace">SLEKT BEER</p>
          <p className="copyright-wrapper-text">All Rights Reserved.</p>
        </Row>
      </div>
    </AntdLayout.Footer>
  )
}

export default Footer
