// @flow

//React
import React, { useContext, useEffect } from 'react'
import { Layout as AntdLayout, Row, Col, notification } from 'antd'

//Contexts
import { store } from '~contexts/store'

//Components
import Filters from '~components/filters'
import List from '~components/list'
import CompareBar from '~components/compare-bar'
import SidebarCollapse from '~components/buttons/sidebar-collapse'
import AdBanner from '~components/ad-banner'

const openErrorModal = ({ description, message }: Object) => {
  return notification.error({
    message,
    description,
  })
}

//Styles
import '~styles/results.scss'

function Results() {
  const { state, dispatch, apiState, asyncDispatch } = useContext(store)
  const { filtersCollapsed, filteredResults, filtersData } = state
  useEffect(() => {
    ;(async () => {
      try {
        const filters = await asyncDispatch({ type: 'api.getFilters' })
        asyncDispatch({ type: 'api.setFilters', value: filters })
      } catch (e) {
        openErrorModal({
          description: 'We are missing the filters',
          message: 'Missing Filters',
        })
        asyncDispatch({ type: 'api.setFilters', value: [] })
      }
      try {
        const items = await asyncDispatch({ type: 'api.getResults' })
        asyncDispatch({ type: 'api.setResults', value: items })
      } catch (e) {
        openErrorModal({
          description: 'We are missing the results',
          message: 'Missing Results',
        })
        asyncDispatch({ type: 'api.setResults', value: [] })
      }
    })()
  }, [])
  return (
    <>
      <div className="site-content">
        <AntdLayout.Content className="filters-toggle-wrapper">
          <Row justify="start">
            <Col>
              <SidebarCollapse />
            </Col>
          </Row>
        </AntdLayout.Content>
        <AntdLayout>
          <AntdLayout.Sider className="filters-sider" collapsed={filtersCollapsed} collapsedWidth="0">
            <Filters filtersData={filtersData} />
          </AntdLayout.Sider>
          <AntdLayout.Content>
            {/*<AdBanner props={{ slot: '1' }} />*/}
            <List filteredResults={filteredResults} />
          </AntdLayout.Content>
        </AntdLayout>
      </div>
      <AntdLayout.Content className="compare-bar-wrapper">
        <CompareBar />
      </AntdLayout.Content>
    </>
  )
}

export default Results
