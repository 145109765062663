// @flow

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Input, Col, Row, Checkbox } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

// Components
import GenModal from '~components/modal'
import LoginForm from '~components/forms/login'

export default function LoginButton() {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button className="button login-button" onClick={showModal} type="text">
        Sign in
      </Button>
      <GenModal onCancel={handleCancel} visible={isModalVisible}>
        <LoginForm />
      </GenModal>
    </>
  )
}
