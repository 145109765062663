// @flow
const slektClient = require('~clients/slekt-client')
import React from 'react'
import _ from 'lodash'
import checkoutConfig from '~checkout-config'

const getHomepageConfig = async (catalogId: string) => {
  const config = await slektClient.getHomepageConfig(catalogId)
  if (_.isObject(config) && !_.isEmpty(config)) {
    return config
  } else {
    throw new Error()
  }
}

const getResults = async (catalogId: string) => {
  const results = await slektClient.getItems(catalogId, { offset: 0, limit: 10 })
  if (_.isArray(results) && !_.isEmpty(results)) {
    return results
  } else {
    throw new Error()
  }
}

const getFilters = async (catalogId: string) => {
  const filters = await slektClient.getFilters(catalogId)
  if (_.isArray(filters) && !_.isEmpty(filters)) {
    return filters
  } else {
    throw new Error()
  }
}

const getQuest = async (catalogId: string) => {
  const quest = await slektClient.getQuest(catalogId, { token: null, mock: true })
  if (_.isObject(quest) && !_.isEmpty(quest)) {
    return quest
  } else {
    throw new Error()
  }
}

const getCheckoutSchema = async () => {
  return checkoutConfig
}

const setQuestAnswers = (state: Object, action: Object) => {
  const { gid, answers } = action.value
  state.questAnswers = {
    ...state.questAnswers,
    [`${gid}`]: {
      answers,
    },
  }
  return { ...state }
}

const addResponse = async (info: Object) => {
  const result = await slektClient.addResponse(info.state.catalogId, 'token', info.value)
  if (result.message) {
    return result
  }
  return
}

const filterFn = (state: Object, action: Object) => {
  const {
    filterFn: { fn, filterType },
  } = action
  let filteredResults = state.resultsData
  const newState = _.assign(state, {
    activeFilters: _.assign(state.activeFilters, { [filterType]: fn }),
  })
  let activeFilters = newState.activeFilters
  let results = []
  for (const filter in activeFilters) {
    results.push(activeFilters[filter](state.resultsData))
  }
  for (const result of results) {
    filteredResults = filteredResults.filter((value: object) => result.includes(value))
  }
  newState.filteredResults = filteredResults
  newState.activeFilters = activeFilters
  return { ...newState }
}

const getConfig = async (catalogId: string) => {
  const config = await slektClient.getCatalogConfig(catalogId)
  if (_.isObject(config) && !_.isEmpty(config)) {
    return config
  } else {
    throw new Error()
  }
}

const orderValidation = async (info: Object) => {
  const result = await slektClient.orderValidation(info.state.catalogId, 'token', info.value)
  if (result.message) {
    return result
  }
  return
}

const resetFilters = (state: Object, action: Object) => {
  const { filtersFn } = action
  filtersFn.map((filter: Object) => {
    filter.filterFn.fn()
  })
  const newState = _.assign(state, {
    filteredResults: state.resultsData,
  })
  return { ...newState }
}

export const apiActions = {
  'api.getHomepageConfig': (catalogId: string) => getHomepageConfig(catalogId),
  'api.getInstanceConfig': (catalogId: string) => getConfig(catalogId),
  'api.getFilters': (catalogId: string) => getFilters(catalogId),
  'api.getQuest': (catalogId: string) => getQuest(catalogId),
  'api.getResults': (catalogId: string) => getResults(catalogId),
  'api.getCheckoutSchema': (catalogId: string) => getCheckoutSchema(catalogId),
  'api.setQuestAnswers': (state: Object, action: Object) => setQuestAnswers(state, action),
  'api.orderValidation': (action: Object) => orderValidation(action),
  'api.addResponse': (action: Object) => addResponse(action),
  'api.isFetching': (state: Object, action: Object) => ({ ...state, isFetching: action.value }),
  'api.setFilters': (state: Object, action: Object) => {
    return {
      ...state,
      filtersData: action.value,
    }
  },
  'api.setResults': (state: Object, { value }: Object) => ({
    ...state,
    filteredResults: value,
    resultsData: value,
  }),
  'api.setQuest': (state: Object, { value }: Object) => ({
    ...state,
    questSchema: value,
  }),
  'api.setCheckoutSchema': (state: Object, { value }: Object) => ({
    ...state,
    checkoutSchema: value,
  }),
  'api.filterFn': (state: Object, action: Object) => {
    const newState = filterFn(state, action)
    return { ...newState }
  },
  'api.resetFilters': (state: Object, action: Object) => resetFilters(state, action),
}

export const apiReducer = (state: Object, action: Object) => {
  const { type } = action
  if (!type) {
    throw new Error('missing type')
  }
  if (!apiActions[type]) {
    throw new Error('missing action type')
  }
  return apiActions[type](state, action)
}
