//@flow

import React, { useState, useEffect } from 'react'
import { List as AntdList, Collapse, Button } from 'antd'

import ItemHeader from '~components/list/list-item/item-header'
import ItemDetails from '~components/list/list-item/item-details'
import ExpandIcon from '~components/list/list-item/expand-icon'

import '~styles/list-item.scss'

const ListItem = ({ props }: Object) => {
  const { item } = props
  const [infoText, setInfoText] = useState('More Info')
  const [hasBorder, setHasBorder] = useState('')
  const [collapseProperties, setCollapseProperties] = useState()

  const changeInfoText = (e: any) => {
    setInfoText(() => (infoText === 'More Info' ? 'Less Info' : 'More Info'))
  }

  useEffect(() => {
    item.selected === true ? setHasBorder('card-wrapper-selected') : setHasBorder('')
  })

  let collapseObject

  const getCollapseObject = (props: Object) => {
    collapseObject = props
  }

  useEffect(() => {
    setCollapseProperties(collapseObject)
  }, [])

  return (
    <Collapse className={`card-wrapper ${hasBorder}`} expandIcon={(props: Object) => getCollapseObject(props)}>
      <Collapse.Panel
        header={<ItemHeader props={{ item, collapseProperties }} />}
        onClick={(event: Any) => event.stopPropagation()}>
        <ItemDetails props={{ item }} />
      </Collapse.Panel>
    </Collapse>
  )
}

export default ListItem
