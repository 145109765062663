// @flow

import React from 'react'

class ErrorHandler extends React.Component {
  constructor(props: Object) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: string, errorInfo: string) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo) {
      return <h2 className="error-handler">Something went wrong!</h2>
    }
    return this.props.children
  }
}

export default ErrorHandler
