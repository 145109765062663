// @flow

//React
import React, { useContext } from 'react'
import { Row, Button, Col } from 'antd'

import { store } from '~contexts/store'
//Styles
import '~styles/compare-bar.scss'

//Components
import CompareButton from '~components/buttons/compare-button'
import SearchInput from '~components/compare-bar/search-input'

function CompareBar() {
  const { state, dispatch } = useContext(store)
  const { selectedItemsArray = [] } = state

  if (!selectedItemsArray.length) {
    return <div />
  }
  return (
    <div className="compare-wrapper">
      <Row>
        <Col span={21}>
          <div className="item-list">
            <ul>
              {selectedItemsArray.map((item: Object, index: number) => {
                return (
                  <li className="item" key={index}>
                    <span className="text">{item.title}</span>
                    <Button
                      className="button"
                      onClick={() => dispatch({ type: 'common.removeItem', value: item })}
                      size="small"
                      type="text">
                      x
                    </Button>
                  </li>
                )
              })}
            </ul>
          </div>
        </Col>
        <Col className="compare-button-wrapper" span={3}>
          <CompareButton props={selectedItemsArray} />
        </Col>
      </Row>
    </div>
  )
}

export default CompareBar
