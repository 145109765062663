// @flow

const switcher = (state: Object) => (state.swapListState === 'list' ? 'table' : 'list')

export const resultsReducer = (state: Object, action: Object) => {
  const { type } = action
  if (!type) {
    throw new Error('missing type')
  }
  switch (type) {
    case 'results.swapResultsView':
      state.swapListState = switcher(state)
      return { ...state }
    default:
      return { ...state }
  }
}
