//@flow
import React from 'react'
import { Form, Input, Typography } from 'antd'
import FormInput from '~components/form-generator/input'
import FormRadioGroup from '~components/form-generator/radio'
import FormCheckbox from '~components/form-generator/checkbox'
import FormButton from '~components/form-generator/button'
import FormDatePicker from '~components/form-generator/datepicker'

const optionsTypeMapper = {
  text: FormInput,
  radio: FormRadioGroup,
  checkbox: FormCheckbox,
  button: FormButton,
  date: FormDatePicker,
}

function Group({ props }: Object) {
  const { form, error, panel, setError, type } = props
  const { gid, key, title, subtitle, items } = panel
  return (
    <>
      <div className="group-title">
        <Typography.Title>{title}</Typography.Title>
      </div>
      <div className="group-subtitle">
        <Typography.Title>{subtitle}</Typography.Title>
      </div>
      {items.map((item: Object, idx: number) => {
        const FormItem = optionsTypeMapper[item.inputType]
        return <FormItem key={idx} props={{ error, form, formType: type, gid, item, setError }} />
      })}
    </>
  )
}

export default Group
