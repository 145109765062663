// @flow

//React
import React, { useState, useEffect } from 'react'
import { Form, Radio, Button, Typography } from 'antd'

function QuestOptions({ props }: Object) {
  const [customError, setCustomError] = useState({})
  const { item, error } = props
  const { label, options, inputType, name } = item

  useEffect(() => {
    if (name === error.key) {
      setCustomError({
        message: error.message,
        status: 'error',
      })
    } else {
      setCustomError({})
    }
  }, [error])

  return (
    <>
      <Typography.Text className="quest-title">{label}</Typography.Text>
      <Form.Item extra={customError.message} name={name} validateStatus={customError.status}>
        <Radio.Group className="form-options">
          {options.map((option: Object, index: number) => {
            return (
              <Radio.Button className="options quest-options" key={index} value={option.value}>
                {option.label}
              </Radio.Button>
            )
          })}
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default QuestOptions
