import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from '~locales'
const { pt, en } = translations

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'pt',
  debug: process.env.NODE_ENV === 'development' ? true : false,
  react: {
    useSuspense: false,
  },
  resources: {
    pt: {
      translation: pt,
    },
    en: {
      translation: en,
    },
  },
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
