// @flow

//React
import React, { useContext } from 'react'

import { store } from '~contexts/store'

function AdBanner({ props }: Object) {
  const { state } = useContext(store)
  const { dataAdClient } = state
  const { slot, className = '', format = '', layout = '', layoutKey = '', responsive = true } = props

  return (
    <ins
      className={`${className} adsbygoogle`}
      data-ad-client={dataAdClient}
      data-ad-format={format}
      data-ad-layout={layout}
      data-ad-layout-key={layoutKey}
      data-ad-slot={slot}
      data-full-width-responsive={responsive}
    />
  )
}

export default AdBanner
