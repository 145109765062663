// @flow

//React
import React, { useState } from 'react'
import { Form } from 'antd'
import Group from '~components/form-generator/group'

function FormGenerator(props: Object) {
  const [form] = Form.useForm()
  const [error, setError] = useState({})
  const { schema, type } = props
  const { key, panels } = schema

  if (Object.keys(schema).length === 0) {
    return <p>Default</p>
  }

  return (
    <Form form={form}>
      {panels.map((panel: Object, idx: number) => {
        return <Group key={idx} props={{ error, form, panel, setError, type }} />
      })}
    </Form>
  )
}

export default FormGenerator
