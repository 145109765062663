// @flow

//React
import React from 'react'
import { Form, Typography, Checkbox } from 'antd'

function FormCheckbox({ props }: Object) {
  const { item } = props
  const { name, label, required, errorMessage } = item
  return (
    <>
      <Typography.Text className="capitalize">{label}</Typography.Text>
      <Form.Item name={name} rules={[{ required: required, message: errorMessage }]} valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </>
  )
}

export default FormCheckbox
