// @flow

import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'antd'

//Contexts
import { store } from '~contexts/store'

function QuestHeader({ props }: Object) {
  const { title, collapseProperties, gid, isCollapsed, setIsCollapsed } = props
  const { state } = useContext(store)
  const { questAnswers, questSchema } = state
  const [panel, setPanel] = useState()

  const expandPanel = () => {
    collapseProperties.collapse.onItemClick('0')
    setIsCollapsed(false)
  }

  useEffect(() => {
    questSchema.panels.map((panel: Object) => {
      if (panel.gid === gid) {
        setPanel(panel)
      }
    })
  }, [])

  if (isCollapsed) {
    return (
      <>
        <Row onClick={(event: Any) => event.stopPropagation()}>
          <Col span={12}>
            <p className="title">{title}</p>
          </Col>
          <Col className="edit-label-wrapper" onClick={(event: Any) => expandPanel()} span={12}>
            <p className="edit-label">Edit</p>
          </Col>
        </Row>
        <Row onClick={(event: Any) => event.stopPropagation()}>
          {questAnswers[gid] &&
            Object.keys(questAnswers[gid].answers).map((key: Object, idx: number) => {
              const question = panel.items.filter((item: Object, idx: number) => item.name === key)[0]
              const answer = questAnswers[gid].answers[key]
              return (
                <Col key={idx} span={24}>
                  <span className="question-preview">
                    {question.label}
                    <span className="answer-preview">{answer}</span>
                  </span>
                </Col>
              )
            })}
        </Row>
      </>
    )
  }

  return (
    <Row onClick={(event: Any) => event.stopPropagation()}>
      <Col span={12}>
        <p className="title">{title}</p>
      </Col>
    </Row>
  )
}

export default QuestHeader
