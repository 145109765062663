// @flow

//React
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

function CompareButton({ props }: Object) {
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    props.length > 1 ? setDisabled(false) : setDisabled(true)
  }, [props])

  return (
    <Button className="compare-button" disabled={disabled} size="middle" type="text">
      <Link
        to={{
          pathname: 'compare',
          state: { props },
        }}>
        Compare
      </Link>
    </Button>
  )
}

export default CompareButton
