// @flow

import React, { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { SettingFilled } from '@ant-design/icons'
import Logout from '~components/buttons/logout'

export default function UserConfig() {
  const menu = (
    <Menu>
      <Menu.Item>
        <Logout />
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <Button className="config-button">
        <SettingFilled />
      </Button>
    </Dropdown>
  )
}
