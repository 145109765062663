// @flow

//React
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout as AntdLayout, Button } from 'antd'

//Stores
import { store } from '~contexts/store'

//Imports
import collapseFilters from '~images/filters.png'

function SidebarCollapse() {
  const { t, i18n } = useTranslation()
  const [iconOrientation, setIconOrientation] = useState()
  const { state, dispatch } = useContext(store)
  const toggle = () => {
    state.filtersCollapsed === true ? setIconOrientation('inverted') : setIconOrientation('')
    dispatch({ type: 'common.setFiltersCollapse' })
  }

  const toggleIcon = () => {
    return (
      <span>
        {t('filtersTitle')} <img className={iconOrientation} src={collapseFilters} width="auto" />
      </span>
    )
  }

  return (
    <>
      <Button className="sidebar-collapse" onClick={() => toggle()} type="text">
        {toggleIcon()}
      </Button>
    </>
  )
}

export default SidebarCollapse
