// @flow

//React
import React, { useState } from 'react'

import backIcon from '~images/back.png'

function Back({ props }: Object) {
  const goBack = () => {
    props.history.goBack()
  }

  return <img className="back-button" onClick={goBack} src={backIcon} width="20" />
}

export default Back
