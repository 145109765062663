// @flow

import _ from 'lodash'

//React
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Layout as AntdLayout } from 'antd'
import QuestForm from '~components/quest-form'
import FormGenerator from '~components/form-generator'

//Contexts
import { store } from '~contexts/store'

//Styles
import '~styles/quest.scss'

function QuestPage() {
  const { state, asyncDispatch } = useContext(store)
  const { questSchema } = state
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        const { catalogQuest } = await asyncDispatch({ type: 'api.getQuest' })
        if (catalogQuest.key === 'default') {
          history.push('/results')
        }
        asyncDispatch({ type: 'api.setQuest', value: catalogQuest })
      } catch (e) {
        asyncDispatch({ type: 'api.setQuest', value: {} })
      }
    })()
  }, [])

  return (
    <AntdLayout.Content className="site-content">
      <div className="quest-background quest-wrapper">
        <QuestForm schema={questSchema} type="quest" />
      </div>
    </AntdLayout.Content>
  )
}

export default QuestPage
