// @flow

import React from 'react'
import { Modal, Button, Form, Col, Row, Input, Checkbox } from 'antd'

//Styles
import '~styles/modal.scss'

//Assets
import logo from '~images/beer-header-logo.png'

const GenModal = ({ children, visible, onCancel }: Object) => {
  return (
    <>
      <Modal bodyStyle={{ padding: '0px' }} className="modal" footer={null} onCancel={onCancel} visible={visible}>
        <Row align="center" className="logo-wrapper" justify="middle">
          <img alt="SLEKT Beer" src={logo} width="50%" />
        </Row>
        {children}
      </Modal>
    </>
  )
}

export default GenModal
