// @flow

import _ from 'lodash'

//React
import React from 'react'
import { Layout as AntdLayout } from 'antd'

//Components
import HomePageSections from '~components/homepage-sections/'

//Styles
import '~styles/homepage.scss'

function HomePage() {
  return (
    <AntdLayout.Content className="site-content">
      <HomePageSections />
    </AntdLayout.Content>
  )
}

export default HomePage
