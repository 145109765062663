// @flow

//React

import React, { useEffect, useContext } from 'react'
import { Layout as AntdLayout, Row, Col } from 'antd'
import i18n from 'i18next'
import OrderPreview from '~components/forms/billing/order-review'
import FormGenerator from '~components/form-generator'

//Contexts
import { store } from '~contexts/store'

//Styles
import '~styles/checkout.scss'

function Checkout() {
  const { state, asyncDispatch } = useContext(store)
  const { checkoutItem, checkoutSchema } = state

  useEffect(() => {
    ;(async () => {
      try {
        const schema = await asyncDispatch({ type: 'api.getCheckoutSchema' })
        asyncDispatch({ type: 'api.setCheckoutSchema', value: schema })
      } catch (e) {
        console.log(e)
        // openErrorModal({
        //   description: 'No checkout',
        //   message: 'Missing Checkout',
        // })
        asyncDispatch({ type: 'api.setCheckoutSchema', value: {} })
      }
    })()
  }, [])
  return (
    <AntdLayout.Content className="site-content">
      <Row className="checkout-wrapper">
        <Col className="background-white billing-form" span={18}>
          <FormGenerator schema={checkoutSchema} type="checkout" />
        </Col>
        <Col className="background-white billing-form" offset={2} span={4}>
          <OrderPreview props={checkoutItem} />
        </Col>
      </Row>
    </AntdLayout.Content>
  )
}

export default Checkout
