// @flow

//React
import React, { useContext } from 'react'
import { Layout as AntdLayout, Form, Input, Button, Row, Col, Checkbox } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

//Contexts
import { store } from '~contexts/store'

//Components
import Login from '~components/forms/login'

function LoginPage() {
  return (
    <AntdLayout.Content className="site-content site-layout">
      <Row justify="center">
        <Col className="form-wrapper">
          <Login />
        </Col>
      </Row>
    </AntdLayout.Content>
  )
}

export default LoginPage
