// @flow

//React
import React, { useContext } from 'react'
import { Select } from 'antd'
const { Option } = Select

import { store } from '~contexts/store'

//styles
import '~styles/compare-bar.scss'

function SearchInput({ props }: Object) {
  const { state, dispatch } = useContext(store)
  const { resultsData } = state
  const onChange = (value: string) => {
    resultsData.forEach((item: Object) => {
      if (item.uuid === value) {
        dispatch({ type: 'common.onAddToCompare', value: item })
      }
    })
  }
  return (
    <>
      <Select
        className="search-input"
        filterOption={(input: string, option: Object) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={onChange}
        showSearch
        size="large">
        {resultsData.map((item: Object, index: number) => {
          return (
            <Option key={index} value={item.uuid}>
              {item.title}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export default SearchInput
