// @flow

import _ from 'lodash'

const setCompareItem = (state: Object, value: Object) => {
  const newState = {
    ...state,
    checkoutItem: value,
  }
  return { ...newState }
}

const selectComparingItems = (state: Object, value: Object) => {
  const { item, side } = value
  const newState = _.assign(state, {
    comparedItems: {
      ...state.comparedItems,
      [`${side}`]: item,
    },
  })
  return { ...newState }
}

const addItem = (state: Object, item: Object) => {
  const selectedItem = _.assign(item, { selected: true })
  return {
    ...state,
    selectedItemsArray: [...state.selectedItemsArray, selectedItem],
  }
}

const removeItem = (state: Object, item: Object) => {
  const selectedItem = _.assign(item, { selected: false })
  return {
    ...state,
    selectedItemsArray: _.filter(state.selectedItemsArray, (item: Object) => item.uuid !== selectedItem.uuid),
  }
}

const onAddToCompare = (state: Object, selectedItem: Object) => {
  if (_.includes(state.selectedItemsArray, selectedItem)) {
    return removeItem(state, selectedItem)
  }
  return addItem(state, selectedItem)
}

const collapseFilters = (state: Object) => ({ ...state, filtersCollapsed: state.filtersCollapsed ? false : true })

const commonActions = {
  'common.setCompareItem': (state: Object, action: Object) => setCompareItem(state, action.value),
  'common.setHomepageConfig': (state: Object, action: Object) => ({ ...state, homepageConfig: action.value }),
  'common.setConfig': (state: Object, action: Object) => ({ ...state, instanceConfig: action.value }),
  'common.onAddToCompare': (state: Object, action: Object) => onAddToCompare(state, action.value),
  'common.setFiltersCollapse': (state: Object, action: Object) => collapseFilters(state),
  'common.removeItem': (state: Object, action: Object) => removeItem(state, action.value),
  'common.selectComparingItems': (state: Object, action: Object) => selectComparingItems(state, action.value),
}

export const commonReducer = (state: Object, action: Object) => {
  const { type } = action
  if (!type) {
    throw new Error('missing type')
  }
  if (!commonActions[type]) {
    throw new Error('missing action type')
  }

  return commonActions[type](state, action)
}
