// @flow

import React from 'react'
import { Form, Button } from 'antd'

function QuestSkipButton({ props }: Object) {
  const { collapseProperties, setIsCollapsed } = props

  const onClick = () => {
    collapseProperties.collapse.onItemClick('0')
    setIsCollapsed(true)
  }

  return (
    <Form.Item className="quest-submit-button-wrapper">
      <Button className="quest-submit-button" onClick={onClick}>
        Skip
      </Button>
    </Form.Item>
  )
}

export default QuestSkipButton
