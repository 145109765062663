// @flow

//React
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Radio, Button, Typography, Form, Collapse } from 'antd'
import QuestBody from '~components/quest-form/quest-body'
import QuestHeader from '~components/quest-form/quest-header'

//Contexts
import { store } from '~contexts/store'

function QuestGroup({ props }: Object) {
  const { gid, items, title } = props
  const { asyncDispatch } = useContext(store)
  const [collapseProperties, setCollapseProperties] = useState()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const history = useHistory()
  const collapseObject = {}

  const getCollapseObject = (props: Object) => {
    collapseObject['collapse'] = props
    collapseObject['gid'] = gid
  }

  useEffect(() => {
    setCollapseProperties(collapseObject)
  }, [])

  return (
    <Collapse
      className="capitalize form"
      defaultActiveKey={['0']}
      expandIcon={(props: Object) => getCollapseObject(props)}>
      <Collapse.Panel
        header={<QuestHeader props={{ title, collapseProperties, gid, isCollapsed, setIsCollapsed }} />}
        key="0">
        <QuestBody props={{ items, collapseProperties, gid, setIsCollapsed }} />
      </Collapse.Panel>
    </Collapse>
  )
}

export default QuestGroup
