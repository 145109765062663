// @flow

//React
import React, { useContext } from 'react'
import { Layout as AntdLayout } from 'antd'
import { store } from '~contexts/store'

//Components
import Header from '~components/layout/header'
import Footer from '~components/layout/footer'
import Main from '~components/layout/main'

function Layout({ children, title }: Object) {
  const { state } = useContext(store)
  const { theme, instanceTheme } = state
  return (
    <AntdLayout className={`${theme} ${instanceTheme}`}>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </AntdLayout>
  )
}

export default Layout
