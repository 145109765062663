// @flow

//React
import React, { useState, useContext } from 'react'
import { Select } from 'antd'
const { Option } = Select

//Images
import dpDown from '~images/dropdown.png'

//Contexts
import { store } from '~contexts/store'

function CompareDropdown({ item, selectedItemsArray, side }: Object) {
  const [isInverted, setIsInverted] = useState('')
  const { state, dispatch } = useContext(store)
  const { comparedItems } = state

  const oppositeSide = side === 'left' ? 'right' : 'left'

  const handleChange = (value: any) => {
    const item = selectedItemsArray.filter((item: Object) => item.uuid === value)[0]
    dispatch({ type: 'common.selectComparingItems', value: { item, side } })
  }

  const icon = () => {
    return <img className={isInverted} src={dpDown} />
  }

  const onClick = () => {
    isInverted === '' ? setIsInverted('inverted') : setIsInverted('')
  }

  return (
    <Select defaultValue={item.uuid} onChange={handleChange} onClick={onClick} suffixIcon={icon}>
      {selectedItemsArray.map((item: Object, idx: number) => {
        if (item.uuid === comparedItems[`${oppositeSide}`].uuid) {
          return (
            <Option disabled key={idx} value={item.uuid}>
              {item.title}
            </Option>
          )
        }
        return (
          <Option key={idx} value={item.uuid}>
            {item.title}
          </Option>
        )
      })}
    </Select>
  )
}

export default CompareDropdown
