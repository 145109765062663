// @flow

//React
import React from 'react'
import { Form, DatePicker, Typography } from 'antd'

function FormDatePicker({ props }: Object) {
  const { item } = props
  const { label, type, name, required, errorMessage, ruleType, ruleMessage, max } = item
  return (
    <>
      <Typography.Text className="capitalize">{label}</Typography.Text>
      <Form.Item name={name}>
        <DatePicker />
      </Form.Item>
    </>
  )
}

export default FormDatePicker
