// @flow
import _ from 'lodash'
import i18n from './i18n'

//React
import React, { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'

//Components
import Layout from '~components/layout'
import Head from '~components/head'

import { store } from '~contexts/store'

//Styles
import 'antd/dist/antd.css'
import '~styles/app.scss'
import '~styles/travel.scss'
import '~styles/beer.scss'
import '~styles/buttons.scss'

//Pages
import Compare from '~pages/compare'
import HomePage from '~pages/hp'
import LoginPage from '~pages/login'
import NotFound from '~pages/not-found'
import QuestPage from '~pages/quest'
import RegisterPage from '~pages/register'
import Results from '~pages/results'
import Checkout from '~pages/checkout'

function App() {
  const { state, dispatch, asyncDispatch } = useContext(store)
  const { instanceConfig } = state
  const { defaultLang } = instanceConfig

  useEffect(() => {
    ;(async () => {
      try {
        const instanceConfig = await asyncDispatch({ type: 'api.getInstanceConfig' })
        dispatch({ type: 'common.setConfig', value: instanceConfig })
      } catch (e) {
        console.log(e)
      }
    })()
    i18n.changeLanguage(defaultLang)
  }, [])
  return (
    <Router>
      <Head />
      <Switch>
        <Route path="/login">
          <Layout title="login">
            <LoginPage />
          </Layout>
        </Route>
        <Route exact path="/quest">
          <Layout title="quest">
            <QuestPage />
          </Layout>
        </Route>
        <Route exact path="/register">
          <Layout title="register">
            <RegisterPage />
          </Layout>
        </Route>
        <Route
          exact
          path="/compare"
          render={(props: Object) => (
            <Layout title="compare">
              <Compare props={props} />
            </Layout>
          )}
        />
        <Route path="/results">
          <Layout title="results">
            <Results />
          </Layout>
        </Route>
        <Route
          exact
          path="/"
          render={(props: Object) => {
            if (_.get(instanceConfig, 'hp.redirectTo', false)) {
              return <Redirect to={`${_.get(instanceConfig, 'hp.redirectTo')}`} />
            }
            return (
              <Layout title="home">
                <HomePage />
              </Layout>
            )
          }}
        />
        <Route exact path="/homepage">
          <Layout title="home">
            <HomePage />
          </Layout>
        </Route>
        <Route exact path="/checkout">
          <Layout title="checkout">
            <Checkout />
          </Layout>
        </Route>
        <Route>
          <Layout title="404">
            <NotFound />
          </Layout>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
