// @flow

//React
import React, { useState } from 'react'
import { Layout as AntdLayout, Form, Input, Button, Radio, Row, Col, Checkbox, DatePicker } from 'antd'
import { UserOutlined, LockOutlined, CheckSquareOutlined, MailOutlined, CalendarOutlined } from '@ant-design/icons'

//Contexts
import { StoreConsumer } from '~contexts/store'

//Style
import '~styles/form.scss'

//Components
import CloseButton from '~components/buttons/close'

function Register() {
  return (
    <Form className="register-form" initialValues={{ remember: true }} name="normal_login">
      <Col className="register-content">
        <Form.Item name="fullname" rules={[{ required: true, message: 'Please write your username.' }]}>
          <Input placeholder="Full Name" prefix={<UserOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item name="username" rules={[{ required: true, message: 'Please write your username.' }]}>
          <Input placeholder="Username" prefix={<UserOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true, message: 'Please write your username.' }]}>
          <Input placeholder="E-mail Address" prefix={<MailOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please write your password.' }]}>
          <Input placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
        </Form.Item>
        <Form.Item name="confirm-password" rules={[{ required: true, message: 'Please confirm your password.' }]}>
          <Input
            placeholder="Confirm password"
            prefix={<CheckSquareOutlined className="site-form-item-icon" />}
            type="password"
          />
        </Form.Item>
        <Form.Item>
          <p className="terms-of-use">
            By creating an account, you agree to <a href="">SLEKT’s Terms of Use</a>.
          </p>
        </Form.Item>
        <Form.Item>
          <Row justify="center">
            <Button className="register-button" htmlType="submit" type="primary">
              Sign up
            </Button>
          </Row>
          <Row className="register-now" justify="center">
            <p>
              Already registered? <a href=""> Sign in.</a>
            </p>
          </Row>
        </Form.Item>
      </Col>
    </Form>
  )
}

export default Register
