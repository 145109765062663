// @flow

//React
import React from 'react'
import { Row, Col, Typography } from 'antd'

//Images
import placeholder from '~images/image-not-found-placeholder-default.png'

//Components
import DropdownButton from '~components/buttons/dropdown'
import CompareDropdown from '~components/compare-item/dropdown'
import BuyButton from '~components/buttons/buy-button'

//Styles
import '~styles/compare-item.scss'

function CompareItem({ item, side, selectedItemsArray }: Object) {
  return (
    <Row className="compare-item-wrapper">
      <Col className="dpdown" span={24}>
        <Row align="middle" className="row">
          <Col className="item-title" span={24}>
            <CompareDropdown item={item} selectedItemsArray={selectedItemsArray} side={side} />
          </Col>
        </Row>
      </Col>
      <Col className="card" span={24}>
        {side === 'left' && (
          <>
            <Row className="buy-btn-wrapper" justify="end">
              <BuyButton item={item} />
            </Row>
            <Row>
              <Col className="card-img-wrapper" lg={24} xs={24}>
                <img
                  className="card-img"
                  onError={(ev: any) => (ev.target.src = placeholder)}
                  src={item.image || placeholder}
                />
              </Col>
              <Col className="props-wrapper" lg={24} xs={24}>
                {item.infos.map((info: Object, idx: number) => {
                  if (idx % 2 == 0)
                    return (
                      <Row className="item-rows grey" key={idx}>
                        <Typography.Text className="capitalize attribute-title"> {info.title} </Typography.Text>
                        <Typography.Text className="attribute-value">
                          {info.value} {info.symbol}
                        </Typography.Text>
                      </Row>
                    )
                  return (
                    <Row className="item-rows" key={idx}>
                      <Typography.Text className="capitalize attribute-title"> {info.title} </Typography.Text>
                      <Typography.Text className="attribute-value">
                        {info.value} {info.symbol}
                      </Typography.Text>
                    </Row>
                  )
                })}
              </Col>
            </Row>
          </>
        )}
        {side === 'right' && (
          <>
            <Row className="buy-btn-wrapper" justify="end">
              <BuyButton item={item} />
            </Row>
            <Row>
              <Col className="card-img-wrapper" lg={24} xs={24}>
                <img
                  className="card-img"
                  onError={(ev: any) => (ev.target.src = placeholder)}
                  src={item.image || placeholder}
                />
              </Col>
              <Col className="props-wrapper" lg={24} xs={24}>
                {item.infos.map((info: Object, idx: number) => {
                  if (idx % 2 == 0)
                    return (
                      <Row className="item-rows grey" key={idx}>
                        <Typography.Text className="capitalize attribute-title"> {info.title} </Typography.Text>
                        <Typography.Text className="attribute-value">
                          {info.value} {info.symbol}
                        </Typography.Text>
                      </Row>
                    )
                  return (
                    <Row className="item-rows" key={idx}>
                      <Typography.Text className="capitalize attribute-title"> {info.title} </Typography.Text>
                      <Typography.Text className="attribute-value">
                        {info.value} {info.symbol}
                      </Typography.Text>
                    </Row>
                  )
                })}
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  )
}

export default CompareItem
