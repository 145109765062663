// @flow

//React
import React, { useState } from 'react'
import { Layout as AntdLayout, Form, Input, Button, Radio, Row, Col, Checkbox, DatePicker } from 'antd'
import { UserOutlined, LockOutlined, CheckSquareOutlined, MailOutlined, CalendarOutlined } from '@ant-design/icons'

//Style
import '~styles/form.scss'

//Components
import Register from '~components/forms/register'

function RegisterPage() {
  return (
    <AntdLayout.Content className="site-content site-layout">
      <Row justify="center">
        <Col className="form-wrapper">
          <Register />
        </Col>
      </Row>
    </AntdLayout.Content>
  )
}

export default RegisterPage
