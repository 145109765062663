// @flow

//React
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { auth0domain, clientId } from '~config'

const AuthProvider = ({ children }: Object) => {
  const history = useHistory()

  const onRedirectCallback = (appState: Object) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      clientId={clientId}
      domain={auth0domain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}>
      {children}
    </Auth0Provider>
  )
}

export default AuthProvider
