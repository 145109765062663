// @flow

//React
import React, { useEffect } from 'react'
import { Layout as AntdLayout } from 'antd'

//Styles
import '~styles/main.scss'

function Main({ children }: Object) {
  return <AntdLayout className="site-content-wrapper">{children}</AntdLayout>
}

export default Main
